.mat-list-popup {
    position: absolute;
    top: 110px;
    left: 50%;
    margin-left: -150px;
    width: 300px;
    border: 1px solid $gray-200;
    z-index: 10000;
    background: white;
    box-shadow: $popup-shadow;
    border-radius: 4px;
    transition: opacity $transition;
    animation: slideDown .3s;
}

mat-list.mat-list {
    &.mat-list-base {
        padding-top: 0;
        padding-bottom: 0;
    }

    .mat-list-item {
        font-family: $text-family;
        transition: $transition;

        &:hover {
            background-color: $gray-100;
        }

        &.-active {
            background-color: $gray-100;
        }
    }
}
