.mat-icon {
    &.material-icons {
        font-size: inherit;
        height: auto;
        width: auto;

        &.icon-xs {
            font-size: 12px;
        }

        &.icon-sm {
            font-size: 16px;
        }

        &.icon-md {
            font-size: 18px;
        }

        &.icon-lg {
            font-size: 20px;
        }

        &.icon-xl {
            font-size: 24px;
        }
    }

    &:not(.material-icons) {
        font-size: 0;

        &.icon-xs {
            width: 12px;
            height: 12px;
        }

        &.icon-sm {
            width: 16px;
            height: 16px;
        }

        &.icon-md {
            width: 18px;
            height: 18px;
        }

        &.icon-lg {
            width: 20px;
            height: 20px;
        }

        &.icon-xl {
            width: 24px;
            height: 24px;
        }
    }
}

