.ft2-screenshots {
    perspective: 1000px;
    padding-bottom: 15%;
    position: relative;
    display: flex;

    &.-left {
        justify-content: flex-end;

        .ft2-screenshot:last-child {
            right: auto;
            left: -10%;
        }
    }

    &.-right {
        justify-content: flex-start;

        .ft2-screenshot:last-child {
            left: auto;
            right: -10%;
        }
    }

    //@include media(sm) {
    //    position: relative;
    //
    //    &:after {
    //        content: '';
    //        position: absolute;
    //        height: 50%;
    //        width: 100%;
    //        left: 0;
    //        background: linear-gradient(to top, $white 30%, transparent);
    //        bottom: 0;
    //        z-index: 1;
    //    }
    //}

    .ft2-screenshot {
        width: 90%;

        @include media(sm) {
            transform: none !important;
        }

        &:first-child {
            margin-top: 20%;
            position: relative;
            z-index: 1;
        }

        &:last-child {
            position: absolute;
            top: 0;
            left: -10%;
            transform-origin: 70%;
        }
    }
}

.ft2-screenshot {
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 1rem 1.3rem 1rem rgba($black, .3);

    @include media(md){
        transform: none !important;
    }

    @include media(sm){
        box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.3);
    }

    @include media(xs){
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    }

    &.-skew_right {
        transform: rotateX(5deg) rotateY(-25deg) rotate(3deg);
        transform-origin: 50%;
    }

    &.-skew_left {
        transform: rotateX(5deg) rotateY(25deg) rotate(-3deg);
        transform-origin: 50%;
    }
}
