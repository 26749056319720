.ft2-bg-dark-tiles {
    background-image: url($image-path + 'assets/img/backgrounds/dark-tiles.jpg');
    background-repeat: no-repeat;
    background-size: 1200px;
    background-position: center;
    background-color: $darker-color;
}

.ft2-bg-onboarding {
    background-image: url($image-path + 'assets/img/backgrounds/onboarding-assistants/teaser-bg-people.jpg') bottom right no-repeat;
}

.ft2-bg-advocacy-teaser {
    background-image: url($image-path + 'assets/img/backgrounds/teasers/teaser-bg-advocacy.jpg');
    background-repeat: no-repeat;
    background-position: center top 200px;
}

.ft2-bg-discovery-teaser {
    background-image: url($image-path + 'assets/img/backgrounds/teasers/teaser-bg-discovery.jpg');
    background-repeat: no-repeat;
    background-position: center top 200px;
}

.ft2-bg-striped {
    background-image: linear-gradient(135deg, $white 28.57%, lighten($gray-200, 3) 28.57%, lighten($gray-200, 3) 50%, $white 50%, $white 78.57%, lighten($gray-200, 3) 78.57%, lighten($gray-200, 3) 100%);
    background-size: 7.00px 7.00px;
}
