.ft2-tooltip-light {
    background-color: $white !important;
    color: $gray-800 !important;
    font-size: 14px;
    line-height: 1.3;
    padding: 15px !important;
    text-align: left !important;
    max-width: calc(100vw - 20px) !important;
    width: 300px;
}
