/*--------------------------------------/
 Text
--------------------------------------*/

p {
    line-height: $line-height;

    i, em {
        font-style: italic;
    }

    b {
        font-weight: 500 !important;
    }

    strong {
        font-weight: 700 !important;
    }

    s {
        text-decoration: line-through;
    }

    &.medium-text, &.small-text{
        line-height: 1.4;
    }
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase !important;
}

.underline {
    text-decoration: underline;
}

.strike-through {
    text-decoration: line-through;
}

/*--------------------------------------/
 Weights
--------------------------------------*/

.bold-text, b {
    font-weight: 500 !important;
}

.strong-text, strong {
    font-weight: 700 !important;
}

/*--------------------------------------/
 Sizes
--------------------------------------*/

.tiny-text {
    font-size: $tiny-font-size;
}

.small-text, small {
    font-size: $small-font-size;
}

.medium-text {
    font-size: $medium-font-size;
}

.base-text {
    font-size: $base-font-size;
}

.large-text {
    font-size: $large-font-size;
}

.huge-text {
    font-size: $huge-font-size;
}

/*--------------------------------------/
 Customs
--------------------------------------*/

.muted-text {
    opacity: 0.6;
}

.solid-text {
    font-weight: bold;
    text-transform: uppercase;
    font-family: $solid-family;
    line-height: 1.2;
}

.heading-text {
    font-family: $bold-family;
}

.ellipsis-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}

/*--------------------------------------/
 Links
--------------------------------------*/

.link {
    cursor: pointer;
    text-decoration: underline;
}

.action-text {
    cursor: pointer;
}

/*--------------------------------------/
 Headers
--------------------------------------*/

.line-header {
    text-align: center;
    width: 100%;
    overflow: hidden;

    &.-muted {
        opacity: 0.3;
    }

    span {
        position: relative;
        padding: 0 1em;

        &:after, &:before {
            content: '';
            position: absolute;
            top: 50%;
            min-width: 50vw;
            border-bottom: 1px solid;
            height: 1px;
            opacity: 0.2;

            @include media(xs) {
                display: none;
            }
        }

        &:after {
            right: 100%;
        }

        &:before {
            left: 100%;
        }
    }
}

$headings: (
    h1: 48, // 3rem
    h2: 42, // 2.625rem
    h3: 36, // 2.25rem
    h4: 32, // 2rem
    h5: 24, // 1.5rem
    h6: 18, // 1.125rem
);

$responsive-headings: (
    h1: (
        xl: 42,
        sm: 36
    ),
    h2: (
        xl: 36,
        sm: 32
    ),
    h3: (
        xl: 32,
        sm: 28
    ),
    h4: (
        xl: 28,
        sm: 24
    ),
    h5: (
        sm: 22
    ),
    h6: (
        sm: 16
    )
);

@mixin create-text-size($selector, $size) {
    #{$selector} {
        font-size: rem($size);
        font-weight: 300;
        @if ($size >= 48) {
            line-height: 1.2;
        } @else if ($size >= 24) {
            line-height: 1.4;
        } @else if ($size >= 36) {
            line-height: 1.3;
        } @else {
            line-height: 1.6;
        }
    }
}

@mixin create-responsive-text-size($selector, $breakpoint, $size) {
    @include media-breakpoint($breakpoint) {
        #{$selector} {
            font-size: rem($size);
        }
    }
}

@each $key, $value in $headings {
    @include create-text-size('.#{$key}', $value);
    @include create-text-size('#{$key}', $value);
}

@each $key, $value in $responsive-headings {
    @each $breakpoint, $size in $value {
        @include create-responsive-text-size('.#{$key}', $breakpoint, $size);
    }
}


/*--------------------------------------/
 Display headers
--------------------------------------*/
$display-headers: (
    display-1: 96, // 6rem
    display-2: 72, // 4.5rem
    display-3: 64, // 4rem
    display-4: 56 // 3.5rem
);

$responsive-displays-headers: (
    display-1: (
        xl: 80,
        sm: 64
    ),
    display-2: (
        xl: 64,
        sm: 56
    ),
    display-3: (
        xl: 56,
        sm: 48
    ),
    display-4: (
        xl: 48,
        sm: 40
    )
);


@each $key, $value in $display-headers {
    @include create-text-size('.#{$key}', $value);
}

@each $key, $value in $responsive-displays-headers {
    @each $breakpoint, $size in $value {
        @include create-responsive-text-size('.#{$key}', $breakpoint, $size);
    }
}
