.ft2-container {
    @include make-gutters();
    max-width: $container-width;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    &.-half {
        max-width: $container-width / 2;
    }

    &.-narrow {
        max-width: $container-width-narrow;
    }

    &.-wide {
        max-width: $container-width-wide;
    }

    &.-wider {
        max-width: $container-width-wider;
    }

    &.-full {
        max-width: 100%;
    }
}

.ft2-block {
    margin: 90px 0;

    @include media(md) {
        margin: 70px 0;
    }

    @include media(sm) {
        margin: 60px 0;
    }

    &.-large {
        margin: 120px 0;

        @include media(md) {
            margin: 90px 0;
        }

        @include media(sm) {
            margin: 70px 0;
        }
    }

    &.-small {
        margin: 60px 0;

        @include media(md) {
            margin: 50px 0;
        }

        @include media(sm) {
            margin: 40px 0;
        }
    }
}

.ft2-section {
    padding: 90px 0;

    @include media(md) {
        padding: 70px 0;
    }

    @include media(sm) {
        padding: 60px 0;
    }

    &.-large {
        padding: 120px 0;

        @include media(md) {
            padding: 90px 0;
        }

        @include media(sm) {
            padding: 70px 0;
        }
    }

    &.-small {
        padding: 60px 0;

        @include media(md) {
            padding: 50px 0;
        }

        @include media(sm) {
            padding: 40px 0;
        }
    }


    &.-skewed {
        margin: 110px 0;
        position: relative;
        background: transparent !important;

        @include media(md) {
            margin: 90px 0;
        }

        @include media(sm) {
            margin: 80px 0;
        }

        &.-large {
            margin: 140px 0;

            @include media(md) {
                margin: 110px 0;
            }

            @include media(sm) {
                margin: 90px 0;
            }
        }

        &.-small {
            margin: 80px 0;

            @include media(md) {
                margin: 70px 0;
            }

            @include media(sm) {
                margin: 60px 0;
            }
        }

        > * {
            z-index: 1;
            position: relative;
        }

        &.-blue-dark, &.-red {
            color: $light-color;
        }

        &.-blue-dark:before {
            background: $blue-night;
        }

        &.-red:before {
            background: linear-gradient(30deg, rgba(58, 109, 117, 1) 0%, rgba(203, 32, 39, 1) 50%, rgba(160, 80, 60, 1) 100%);;
        }

        &:before {
            z-index: 0;
            top: 0;
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            transform: skewY(-8deg);
        }
    }

    &.-path {
        position: relative;

        &:before, &:after {
            content: '';
            position: absolute;
            background: rgba($secondary-color, 0.7);

            @include media(md) {
                background: rgba($secondary-color, 0.3);
            }
        }

        &:before {
            top: 0;
            left: 50%;
            margin-left: -2px;
            width: 4px;
            height: 100%;
        }

        &:last-of-type:after {
            left: 50%;
            top: 100%;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            transform: translate(-50%, 0);
        }
    }
}

.ft2-page-surface {
    z-index: 1000;
    position: relative;
}

.ft2-page-overlay {
    min-width: 100vw;
    min-height: 100vh;
}

.ft-sections-nav {
    &.-light {
        color: $white;
    }
}
