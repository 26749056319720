
.ft-feature-card {
    border-radius: 2px;
    background-color: $violet;
    color: $white;
    position: relative;
    background-size: cover;
    height: 350px;
    padding: 50px 25px;
    cursor: pointer;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    transition: all $transition;
    transition-property: transform, box-shadow, opacity;
    overflow: hidden;
    font-size: $base-font-size;

    //modifiers

    &.-disabled {
        cursor: default;
        pointer-events: none;
    }

    &.-inactive {
        &:before {
            content: '';
            @include overlay;
            z-index: 10;
            background: rgba($white, 0.5);
        }
    }

    &.-overlay {
        &:not(.-disabled):hover:after {
            opacity: 0.8;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(135deg, rgba($black, 0.5) 30%, rgba($black, 0.1) 100%);
            transition: opacity .2s linear;
        }
    }

    &:not(.-disabled):hover {
        transform: translate(0, -5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, .3)
    }

    &.-light {
        background-color: $white;
        color: $violet;
    }

    &.-dark {
        background-color: $violet;
        color: $white;
    }

    &.-large {
        height: 570px;
        padding: 60px 30px;
        font-size: 20px;

        .ft-feature-card_title {
            font-size: 80px;

            @include media(xl) {
                font-size: 70px;
            }

            @include media(md) {
                font-size: 50px;
            }

        }

        .ft-feature-card_coming-soon {
            top: 10px;
            right: 10px;
        }
    }

    &.-medium {
        height: 300px;
        font-size: 16px;

        .ft-feature-card_title {
            font-size: 36px;
        }

        .ft-feature-card_coming-soon {
            top: 5px;
            right: 5px;
        }
    }

    &.-small {
        height: 275px;
        padding: 35px 15px;
        font-size: 14px;

        .ft-feature-card_title {
            font-size: 32px;

            div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .ft-feature-card_coming-soon {
            top: 5px;
            right: 5px;
        }

        .ft-feature-card_term {
            left: 15px;

            &:after {
                background: repeating-linear-gradient(
                        to right,
                        white,
                        white 1px,
                        transparent 0,
                        transparent 4px
                );
            }
        }
    }

    //elements

    p {
        line-height: 1.3;
    }

    &_title {
        line-height: 1;
        font-family: $bold-family;
        margin-bottom: 0.2em;
        font-size: 36px;
        display: flex;
        flex-direction: column;

        small {
            font-size: 50%;
        }
    }

    &_coming-soon {
        position: absolute;
        z-index: 15;
        color: #222;
        background: #fff;
        border-radius: 2px;
        padding: 0.5em 1em;
        @include solid-family(0.8em);
        letter-spacing: 0;
    }

    &_term {
        position: absolute;
        bottom: 10%;
        left: 30px;
        right: 0;
        z-index: 1;
        @include solid-family(inherit);
        letter-spacing: 0;

        &:after {
            content: '';
            display: block;
            margin-top: 0.3em;
            height: 0.6em;
            background: repeating-linear-gradient(
                    to right,
                    white,
                    white 2px,
                    transparent 0,
                    transparent 6px
            );
        }
    }

    &_content {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    &_logo {
        position: absolute;
        height: 2em;
        z-index: 5;

        &.-on-top {
            top: 1.5rem;
            left: 35px;
        }

        &.-on-bottom {
            bottom: 1rem;
            right: 1rem;
        }
    }

    &_year {
        font-size: 10.5rem;
        line-height: 1;

        @include media(xl) {
            font-size: 16vw;
        }

        @include media(md) {
            font-size: 7rem;
        }
    }

    .techstack-decoration {
        position: relative;
        width: 50%;
        height: .06em;
        background: #fff;
        opacity: .6;
        margin-top: .25em;

        &:before, &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            display: block;
            height: .03em;
            background: #fff;          
        }

        &:before {
            width: 75%;
            opacity: .4;
            margin-top: .08em;
        }

        &:after {
            width: 33%;
            opacity: .4;
            margin-top: .2em;
        }
    }
}
