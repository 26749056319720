.ng-valid, .-valid {
    //border-color: $success-color;
}

.ng-invalid, .-invalid {
    //border-color: $danger-color;
}

.ft2-form-error {
    display: block;
    margin: 10px auto 1.5em;
    padding: 10px 15px;
    background: rgba($danger-color, 0.3);
    border-radius: 2px;
    overflow: hidden;
    animation: slideDown .2s cubic-bezier(0, 0, 0.1, 1);
    width: 100%;
}

.ft2-form-group {
    margin-bottom: 20px;

    > label {
        display: inline-block;
        margin-bottom: 5px;
        @include solid-family();
    }
}
