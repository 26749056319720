hr {
    height: 1px;
    border: none;
    margin: 1em 0;
    opacity: 1 !important;

    &.hr-vertical {
        margin: 0 10px;
        display: inline-block;
        height: 1em;
        width: 1px;
        background: none;
        border-left: 1px solid;
    }
}

.ft2-hr {
    background-color: $gray-300;
    height: 1px;
    border: none;
    margin: 0;
}
