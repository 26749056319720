@mixin icon {
    direction: ltr;
    display: inline-block;
    font-family: $icon-family;
    font-size: inherit;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

@mixin overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

@mixin bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@mixin darker-hover($color) {
    color: $color;
    transition: color .3s ease-out;

    &:hover {
        color: darken($color, 15);
    }
}

@mixin underline-hover($height: 1px, $color: inherit) {
    position: relative;
    display: inline-block;

    &:before {
        content: '';
        position: absolute;
        height: 0;
        color: $color;
        border-bottom: $height solid;
        width: 100%;
        left: 0;
        transition: opacity .05s linear, bottom .1s .1s;
        opacity: 0;
        bottom: 7px;
    }

    &:hover:before {
        width: 100%;
        left: 0;
        bottom: 0;
        opacity: 1;
        transition: opacity .1s linear, bottom .2s;
    }
}

@mixin underline-opacity($height: 1px, $opacity-start: 0.5) {
    position: relative;
    display: inline-block;

    &:before {
        display: none !important;
    }

    &:after {
        content: '';
        opacity: $opacity-start;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: $height solid;
        transition: opacity $transition;
    }

    &:hover:after {
        opacity: 1;
    }
}

@mixin underline($height: 1px, $color: inherit) {
    position: relative;
    display: inline-block;

    &:before {
        display: none !important;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: $height solid;
    }

    &:hover:after {
        animation: underline-slide .6s linear;
    }

    @keyframes underline-slide {
        0% {
            left: 0;
        }

        50% {
            left: 100%;
            right: 0;
        }

        51% {
            left: 0;
            right: 100%;
        }

        100% {
            right: 0;
        }
    }
}

@mixin hide-scroll {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin clearfix {
    *zoom: 1;

    &:before, &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

}

@mixin ie-hack {
    //ie edge
    @supports (-ms-ime-align:auto) {
        @content;
    }

    //ie 10+
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

@mixin ie-10-hack {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .ratio-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
}

@mixin autocomplete($bg-color: $white, $text-color: $base-color) {
    -webkit-box-shadow: 0 0 0px 1000px $bg-color inset !important;
    -webkit-text-fill-color: $text-color !important;
}

@mixin solid-family($font-size: 13px, $letter-spacing: 0.025em) {
    font-size: $font-size;
    font-family: $solid-family;
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
    line-height: 1.2;
}
