mat-button-toggle.mat-button-toggle {
    font-family: $text-family;
    font-weight: normal;
    background: $gray-100;
    color: $gray-500;
    border-color: $gray-200;
    transition: color $transition;

    .mat-ripple, .mat-button-toggle-focus-overlay {
        display: none !important;
    }

    &:hover {
        color: $gray-600;
    }


    &.mat-button-toggle-checked {
        background: $white;
        color: $base-color;

        * {
            cursor: default;
        }
    }

    &.mat-button-toggle-appearance-standard {
        border-color: $gray-200;

        .mat-button-toggle-label-content {
            line-height: 40px;
            padding: 0 23px;
        }
    }
}
