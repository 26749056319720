@import '../globals/variables';

.flex-box {
    display: flex !important;
}

$flex-alignment-modifiers: (
    start: flex-start,
    end: flex-end,
    center: center,
    between: space-between,
    around: space-around,
) !default;


@mixin make-flex-helpers($alignments, $breakpoints: $grid-breakpoints) {
    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @include media-breakpoint($breakpoint, $breakpoints) {
            @each $prop, $value in $alignments {
                .v#{$infix}-#{$prop} {
                    display: flex !important;
                    align-items: $value !important;
                }
                .h#{$infix}-#{$prop} {
                    display: flex !important;
                    justify-content: $value !important;
                }
            }

            .f#{$infix}-nowrap {
                display: flex !important;
                flex-wrap: nowrap !important;
            }

            .f#{$infix}-wrap {
                display: flex !important;
                flex-wrap: wrap !important;
            }

            .f#{$infix}-column {
                display: flex !important;
                flex-direction: column !important;
            }

            .f#{$infix}-row {
                display: flex !important;
                flex-direction: row !important;
            }

            .f#{$infix}-column-reverse {
                display: flex !important;
                flex-direction: column-reverse !important;
            }

            .f#{$infix}-row-reverse {
                display: flex !important;
                flex-direction: row-reverse !important;
            }

            .text#{$infix}-left {
                text-align: left !important;
            }

            .text#{$infix}-center {
                text-align: center !important;
            }

            .text#{$infix}-right {
                text-align: right !important;
            }
        }
    }
}

@include make-flex-helpers($flex-alignment-modifiers);
