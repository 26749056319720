.cdk-overlay-container{
    z-index: 3000;
}

.cdk-overlay-pane {
    position: relative !important;
    max-width: 100vw !important;
    width: 100%;
    height: 100%;
}

.cdk-global-scrollblock {
    overflow: hidden;
}

.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
    overflow: auto;
    pointer-events: auto;
}

.cdk-overlay-dark-backdrop {
    background: rgba($dark-color, 0.98);

    & + .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-dialog-container {
    }
}

.cdk-overlay-backdrop {
    &.-light {
        background: $white;

        & + .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-dialog-container {
            padding: 0;
            color: $base-color;
        }
    }

    &.-dark {
        background: rgba($dark-color, 0.98);
        color: $white;

        & + .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-dialog-container {
            padding: 0;
            color: $white;
        }
    }
}

.mat-dialog-container {
    background: none !important;
    color: inherit;
    box-shadow: none;
    overflow: visible !important;
    display: flex !important;
    justify-content: center;

    @include media(xs) {
        padding: 20px 0 !important;
    }

    .ft2-page-overlay {
        min-width: 100% !important;
    }
}

.ft2-light-dialog {
    position: relative;
    padding-top: 75px;
    min-height: 100vh;

    .ft2-light-dialog_controls {
        position: absolute;
        right: 0;
        top: 0;
        padding: 20px 75px 20px 20px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        background: $white;
        z-index: 1;

        > * {
            margin-left: 10px;
        }
    }

}

