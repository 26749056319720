.ft2-spaces {
    background: rgba($darker-color, 0.98);
    padding: 25px 0 30px;
    color: $white;
    min-height: 100vh;
    overflow: auto;

    .ft-dropdown {
        @include solid-family();
        opacity: 0.6;
        transition: opacity .2s ease;

        &:hover, &.active {
            opacity: 1;
        }

        li {
            font-size: 13px;
        }
    }

    .ft-btn-close {
        position: relative;
        top: -10px;
        right: -10px;
    }
}


.ft2-space-cards {
    list-style: none;
    overflow: auto;
    padding: 10px 6px 20px 6px;
    overflow-y: hidden;
    text-align: center;
    white-space: nowrap;

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $blackish;
    }

    &::-webkit-scrollbar {
        height: 12px;
        background-color: $blackish;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $dark-color;
    }

    li {
        margin: 0 3px;
        width: 224px;
        height: 100%;
        display: inline-block;
        white-space: normal;
        vertical-align: top;

        @include media(sm) {
            width: 200px;
        }

        @include media(xs) {
            width: 170px;
        }

        &.cell {
            margin: 0;
        }
    }

    &_placeholder {
        display: flex !important;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100% !important;
        background-image: linear-gradient(90deg, rgba($dark-color, 0.5) 48.67%, transparent 48.67%, transparent 50%, rgba($dark-color, 0.5) 50%, rgba($dark-color, 0.5) 98.67%, transparent 98.67%, transparent 100%);
        background-size: 452.00px 452.00px;
        margin: 0 !important;
        height: 300px !important;
    }
}

.fg2-space-card-name {
    font-size: $small-font-size;
    text-transform: uppercase;
    opacity: 0.3;
    line-height: 1.2 !important;
    width: 100%;
    word-break: break-word;
    margin-top: 15px;
    padding: 0 1em;
}

.ft2-space-card {
    width: 100%;
    height: 300px;
    border-radius: 2px;
    display: flex;
    background-color: $gray-700;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all $transition;

    @include media(sm) {
        height: 280px;
    }

    @include media(xs) {
        height: 260px;
    }

    &:hover {
        transform: translate(0, -5px);

        &:before {
            opacity: 0.5;
        }
    }

    &.-collaborative {
        height: 240px;
        display: block;
        padding: 15px 20px 15px;

        @include media(md) {
            min-height: 240px;
            height: auto;
            padding: 20px;
        }

        .ft2-space-card_name {
            font-size: $large-font-size;
        }

        .ft2-space-card_logo {
            max-width: 300px !important;
            max-height: 100%;
        }
    }

    > * {
        position: relative;
        z-index: 1;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba($dark-color, 0.9), rgba($dark-color, 0.4));
        transition: opacity .2s ease-in-out;
    }

    &_logo {
        max-width: 150px !important;
        max-height: 50%;
    }

    &_name {
        font-family: $solid-family;
        text-transform: uppercase;
        padding: 10px;
        text-align: center;
        word-break: break-word;

        @include media(xs) {
            font-size: $small-font-size;
        }
    }

    &_footer {
        position: absolute;
        bottom: -1.5em;
    }
}

