@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(30px) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: translateY(0) translateZ(0);
        opacity: 1;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-30px) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: translateY(0) translateZ(0);
        opacity: 1;
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(30px) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: translateX(0) translateZ(0);
        opacity: 1;
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(-30px) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: translateX(0) translateZ(0);
        opacity: 1;
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(0.5) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: scale(1) translateZ(0);
        opacity: 1;
    }
}

@keyframes scaleDown {
    0% {
        transform: scale(1.5) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: scale(1) translateZ(0);
        opacity: 1;
    }
}

@keyframes flipUp {
    0% {
        transform: rotateX(90deg) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: rotateX(0) translateZ(0);
        opacity: 1;
    }
}

@keyframes flipDown {
    0% {
        transform: rotateX(90deg) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: rotateX(0) translateZ(0);
        opacity: 1;
    }
}
