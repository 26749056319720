.ft2-dot {
    display: inline-block;
    height: 10px;
    width: 10px;
    opacity: 0.3;
    background: $base-color;
    border-radius: 50%;
    margin: 0 8px;

    &.-primary {
        background: $blue;
        opacity: 1;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: 1px solid $blue;
        }
    }
}
