.mat-tab-group {
    .mat-tab-label-container {
    }

    &.mat-tab-group-nav {
        font-family: $text-family;
        width: 100%;

        .mat-ripple-element {
            display: none;
        }

        .mat-tab-header {
            padding: 0;
        }

        .mat-tab-list {
            height: 60px;
            width: auto;
            margin: auto;
            flex: none;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            font-size: $medium-font-size;
        }

        .mat-tab-labels {
            height: 100%;
        }

        .mat-tab-label {
            padding: 0 30px !important;
            min-width: 0;
            text-transform: uppercase;
            font-weight: bold;
            height: 100% !important;

            &.mat-tab-label-active {
                opacity: 1;
            }
        }

        .mat-tab-label-content {
            text-transform: uppercase;
            font-family: $solid-family;
            font-size: 13px;
            letter-spacing: 1.3px;
            font-weight: bold;
            color: $gray-900;
        }

        .mat-ink-bar {
            border-bottom: 2px solid;
            background-color: $black !important;
        }

        .mat-tab-body-wrapper {
            margin-top: 0;
        }
    }
}




