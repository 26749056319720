$journey-green: #66AB75;
$journey-cranberry: #D5498E;
$journey-blue: #486FD9;

.ft2-journey {

    &.-theme_blue {
        .ft2-journey-section:before {
            background: lighten($journey-blue, 20%);
        }

        .ft2-journey-circle, .ft2-journey-label {
            background: linear-gradient(to bottom, $journey-blue, lighten($journey-blue, 10%));
            color: white;
        }

        .ft2-journey-frame {
            border-color: lighten($journey-blue, 10%);
        }
    }

    &.-theme_striped {
        .ft2-journey-section:before, .ft2-journey-circle, .ft2-journey-label {
            background-image: $striped-gradient;
            background-size: 7.00px 7.00px;
        }

        .ft2-journey-frame {
            border-color: $gray-200;
        }
    }
}

.ft2-journey-nav {
    display: flex;
    justify-content: center;
}

.ft2-journey-group {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
}

.ft2-journey-card {
    height: 450px;
    width: 100%;
    max-width: 800px;
    box-shadow: $card-shadow;
    border: 1px solid $gray-200;
    padding: 60px 30px 0;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &_summary {
        max-width: 32em;
        margin: 20px auto;
    }

    &_person {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: -50px;
    }
}

.ft2-journey-frame {
    position: relative;
    border: 6px solid $gray-200;
    background: white;
    padding: 30px;
    z-index: 1;
    text-align: center;
}


.ft2-journey-profile {
    padding: 20px;
    border-radius: 50%;
    width: 128px;
    height: 128px;
}

.ft2-journey-section {
    position: relative;

    padding: 90px 0;

    @include media(md) {
        padding: 70px 0;
    }

    @include media(sm) {
        padding: 60px 0;
    }

    &.-large {
        padding: 120px 0;

        @include media(md) {
            padding: 90px 0;
        }

        @include media(sm) {
            padding: 70px 0;
        }
    }

    &.-small {
        padding: 60px 0;

        @include media(md) {
            padding: 50px 0;
        }

        @include media(sm) {
            padding: 40px 0;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -4px;
        width: 8px;
        height: 100%;
        background-image: $striped-gradient;
        background-size: 7.00px 7.00px;

        @include media(md) {
            opacity: 0.5;
        }
    }

    &.-path_blue:before {
        background: lighten($journey-blue, 20%) !important;
    }

    &_content {
        position: relative;
        z-index: 1;
    }

    &_circle {
        min-width: 140px;
        width: 20%;
    }

    &:last-child {
        padding-bottom: 0 !important;
    }
}

.ft2-journey-label {
    background: $gray-300;
    min-width: 250px;
    padding: 12px 30px;
    min-height: 50px;
    font-family: $solid-family;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 25px;
    position: relative;
    z-index: 1;

    &.-striped {
        background-size: 7.00px 7.00px !important;
        background-image: $striped-gradient !important;
    }
}

.ft2-journey-circle {
    position: relative;
    z-index: 1;
    min-width: 128px;
    min-height: 128px;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $gray-300;


    &.-pure {
        background: none !important;
    }

    &.-solid {
        &:before {
            width: 85%;
            height: 85%;
        }
    }

    &.-striped {
        background-size: 7.00px 7.00px !important;
        background-image: $striped-gradient !important;
    }

    &.-blue {
        background-image: linear-gradient(to bottom, $journey-blue, lighten($journey-blue, 10%)) !important;
        background-size: 100% !important;
    }

    &_profile {
        background-image: url($image-path + 'ft-profile-anon2.jpg');
        background-color: $red;
        background-size: cover;
        border: 6px solid $white;
        border-radius: 50%;
        height: 90%;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-family: $solid-family;
        color: $white;
    }

    &_icon {
        min-width: 40%;
        min-height: 40%;
    }

    > * {
        position: relative;
        z-index: 1;
    }

    &:before {
        content: '';
        background: $white;
        width: 90%;
        height: 90%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        z-index: 0;
    }
}
