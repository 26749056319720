.ft-create-area {
    width: 60px;
    height: 60px;
    background: none;
    border: 1px dashed $gray-500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: 15px;

    &:before {
        content: '+';
        font-size: 24px;
        color: $gray-500;
    }
}
