@import '~highlight.js/styles/github.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
//@import '~material-design-icons/iconfont/material-icons.css';

/*--------------------------------------/
 Globals
--------------------------------------*/
@import "globals/variables";
@import "globals/functions";
@import "globals/media";
@import "globals/mixins";
@import "globals/animations";


/*--------------------------------------/
 Grid
--------------------------------------*/
@import "grid/grid-variables";
@import "grid/grid-breakpoints";
@import "grid/grid-mixins";
@import "grid/grid-framework";

.ft2 {
    /*--------------------------------------/
     Grid
    --------------------------------------*/
    @import "grid/grid";

    /*--------------------------------------/
     Base
    --------------------------------------*/
    @import "base/main";
    @import "base/colors";
    @import "base/fonts";
    @import "base/typography";
    @import "base/text-content";
    @import "base/layout";

    font-family: $text-family;
}

/*--------------------------------------/
 Helpers
--------------------------------------*/
@import "helpers/alignment";
@import "helpers/displays";
@import "helpers/helpers";
@import "helpers/spacing";
@import "helpers/customs";

/*--------------------------------------/
 Pages
--------------------------------------*/
@import "pages/touchpoint"; //add ft2
@import "pages/journeys";
@import "pages/spaces";
@import "pages/auth";


/*--------------------------------------/
 Elements
--------------------------------------*/
@import "elements/header";
@import "elements/badge";
@import "elements/snapshot";
@import "elements/create-area";
@import "elements/feature-card-view";
@import "elements/feature-cards";
@import "elements/table";
@import "elements/browser";
@import "elements/link";
@import "elements/spinner";
@import "elements/checklist";
@import "elements/screenshots";
@import "elements/nav";
@import "elements/tooltip";
@import "elements/backgrounds";
@import "elements/borders";
@import "elements/hr";
@import "elements/edit-panel";
@import "elements/banners";
@import "elements/d3";
@import "elements/tree";
@import "elements/control-panel";
@import "elements/list-item";
@import "elements/label";

/*--------------------------------------/
 Forms
--------------------------------------*/
@import "forms/button";
@import "forms/input";
@import "forms/forms";
@import "forms/select";
@import "forms/radio";
@import "forms/checkbox";

/*--------------------------------------/
 Customs
--------------------------------------*/
@import "customs/ng-select";
@import "customs/ag-grid";
@import "customs/swiper";


/*--------------------------------------/
 Material
--------------------------------------*/
@import "material/mat-dialog";
@import "material/mat-tabs";
@import "material/mat-icons";
@import "material/mat-toggle";
@import "material/mat-button-toggle-group";
@import "material/mat-list";
@import "elements/tooltip";
@import "elements/editors";
@import "customs/ngb-datepicker";
@import "customs/ngb-tooltip";




