.ft2-control-panel {
    border-bottom: 1px solid $gray-200;
    padding-bottom: 5px;
    margin-bottom: 40px;

    &_name {
        @include solid-family(13px);
        border-bottom: 2px solid $dark-color;
        display: inline-block;
        padding-bottom: 7px;
        transform: translate(0, 6px);
    }
}
