$checkbox: (
    size: 20px,
    check-size: 30px,
    background-color: $white,
    border-color: $gray-500,
    border-width: 2px,
    mark-color: $primary-color,
    label-margin: 15px,
    border-radius: 0,
    padding: 15px,
    duration: .2s
);

.ft2-checkbox {
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    &_control {
        height: map-get($checkbox, 'size');
        width: map-get($checkbox, 'size');
        min-width: map-get($checkbox, 'size');
        background-color: transparent;
        border: map-get($checkbox, 'border-width') solid map-get($checkbox, 'border-color');
        border-radius: map-get($checkbox, 'border-radius');
        position: relative;
        display: inline-block;
        transition: border-color ease map-get($checkbox, 'duration')/2;
        cursor: pointer;
        margin-right: map-get($checkbox, 'label-margin');

        &::before, &::after {
            position: absolute;
            height: 0;
            width: map-get($checkbox, 'check-size') * .15;
            background-color: map-get($checkbox, 'mark-color');
            display: inline-block;
            transform-origin: left top;
            border-radius: 1px;
            content: ' ';
            transition: opacity ease .5s;
            opacity: 0;
        }

        &::before {
            top: map-get($checkbox, 'check-size') * .48;
            left: map-get($checkbox, 'check-size') * .28;
            box-shadow: 0 0 0 2px map-get($checkbox, 'background-color');
            transform: rotate(-135deg);
        }

        &::after {
            top: map-get($checkbox, 'check-size') * .2;
            left: map-get($checkbox, 'check-size') * 0;
            transform: rotate(-45deg);
        }
    }

    input[type=checkbox] {
        display: none;
    }

    input[type=checkbox]:checked + .ft2-checkbox_control {
        &::after {
            opacity: 1;
            height: map-get($checkbox, 'check-size') * .5;
            animation: dothabottomcheck map-get($checkbox, 'duration')/2 ease 0s forwards;
        }

        &::before {
            opacity: 1;
            height: map-get($checkbox, 'check-size') * 1.2;
            animation: dothatopcheck map-get($checkbox, 'duration') ease 0s forwards;
        }
    }

    @keyframes dothabottomcheck {
        0% {
            height: 0;
        }
        100% {
            height: map-get($checkbox, 'check-size') * 0.35;
        }
    }

    @keyframes dothatopcheck {
        0% {
            height: 0;
        }
        50% {
            height: 0;
        }
        100% {
            height: map-get($checkbox, 'check-size') * 0.7;
        }
    }
}
