.ft2-spinner {
    margin: 0 5px;
    height: .8em;
    width: .8em;
    border-radius: 50%;
    position: relative;
    display: inline-block;

    &:before, &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
    }

    &:before {
        border: 1px solid;
        opacity: 0.2;
    }

    &:after {
        border-top: 1px solid;
        border-right: 1px solid transparent;
        animation: spinner .8s linear infinite;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}
