.ft2-banner {
    min-height: 150px;
    display: flex;
    padding: 30px;
    color: $light-color;
    position: relative;
    background: $secondary-color center center no-repeat;
    background-size: cover;
    border-radius: 2px;
    overflow: hidden;
    cursor: pointer;

    &:before {
        @include overlay;
        content: '';
        background-image: linear-gradient(135deg, rgba($darker-color, 1), rgba($darker-color, 0));
        z-index: 0;
    }

    button {
        float: right;
    }

    @include media(sm) {
        button {
            float: none;
        }
    }
}
