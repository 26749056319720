.ft2-list-item {
    padding: 20px;
    border-bottom: 1px solid $gray-200;
    transition: all $transition;
    box-shadow: none;

    &:last-child{
        border-bottom: none;
    }

    &:hover{
        cursor: pointer;
        box-shadow: $popup-shadow;
    }
}
