.ft2-browser {
    background-color: $gray-400;
    position: relative;
    border-radius: 4px;
    padding-top: 30px;
    display: flex;
    align-items: baseline;

    &_scroll {
        max-height: 400px;
        overflow: auto;
    }

    &.-small {
        padding-top: 18px;

        &:before {
            border-radius: 2px 2px 0 0;
            height: 18px;
            font-size: 8px;
        }
    }

    &.-shadow {
        box-shadow: 0 1px 3px 2px rgba($dark-color, 0.15);
    }

    &:before {
        content: '⬤ ⬤ ⬤';
        text-align: left;
        position: absolute;
        font-family: monospace;
        border-radius: 4px 4px 0 0;
        height: 30px;
        width: 100%;
        background: $gray-300;
        color: $gray-500;
        left: 0;
        top: 0;
        font-size: 10px;
        padding-left: 5px;
        display: flex;
        align-items: center;
    }

    img {
        width: 100%;
    }
}
