.ft2-header {
    position: relative;
    z-index: 0;
    text-align: center;
    color: $white;
    background-color: $dark-color;
    min-height: 360px;
    height: 33vh;
    display: flex;
    flex-direction: column;

    @media screen and (min-height: 1024px) {
        height: auto !important;
    }

    @include media(md) {
        height: auto;
        min-height: 270px;
    }

    &.-transparent {
        background-color: transparent;

        &:before {
            @include overlay;
            content: '';
            background-image: linear-gradient(to bottom, rgba($darker-color, 0.5), rgba($darker-color, 0.3) 50%, rgba($darker-color, 0.05) 85%, rgba($darker-color, 0));
            z-index: 1;
        }
    }

    &.-taller {
        height: 66vh;
        min-height: 520px;

        @include media(md) {
            height: auto;
            min-height: 380px;
        }
    }

    &.-tall {
        height: 50vh;
        min-height: 480px;

        @include media(md) {
            height: auto;
            min-height: 320px;
        }
    }

    &.-short {
        height: 20vh;
        min-height: 270px;

        @include media(md) {
            height: auto;
            min-height: 250px;
        }
    }

    &.-shorter {
        height: 10vh;
        min-height: 130px;

        @include media(md) {
            height: auto;
            min-height: 125px;
        }
    }

    &.-admin {
        background: transparent;
        color: $base-color;
        height: auto;
        min-height: 180px;
        overflow: inherit;
        z-index: 1;
    }

    &.-matte {
        &:before {
            @include overlay;
            content: '';
            background-image: linear-gradient(to bottom, $darker-color, rgba($darker-color, 0.5) 25%, rgba($darker-color, 0.5));
            z-index: 1;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 25%;
            bottom: 0;
            z-index: 1;
            background-image: linear-gradient(to top, rgba($darker-color, 0.5), transparent);
        }

        &.-admin {
            &:before, &:after {
                display: none;
            }
        }
    }

    &.-blur {
        overflow: hidden;

        .ft2-header_background {
            transform: scale(1.1);
            filter: blur(5px);
        }
    }

    &.-blurry {
        overflow: hidden;

        .ft2-header_background {
            transform: scale(1.1);
            filter: blur(30px);
        }
    }

    &.-fade-out {
        &_light {
            &:after {
                @include overlay;
                content: '';
                z-index: 1;
                height: 100%;
                background-image: linear-gradient(to top, $white, transparent 50%);
            }
        }

        &_dark {
            &:after {
                @include overlay;
                content: '';
                z-index: 1;
                height: 100%;
                background-image: linear-gradient(to top, $darker-color, transparent 50%);
            }
        }
    }

    &_background {
        @include overlay;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
    }

    &_heading {
        max-width: 20em;
        margin-left: auto;
        margin-right: auto;
    }

    &_content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        padding-top: 100px;
        padding-bottom: 100px;
        flex: 1;

        .ft2-header.-admin & {
            padding-bottom: 0;
        }
    }

    &_summary {
        max-width: 35em;
        margin-left: auto;
        margin-right: auto;
    }

    &_bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
    }

    // from old styles

    .ft-sections-nav {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    &.-people .ft2-header_background {
        background-image: url($image-path + 'ft-people-header-bg.jpg');
    }

    &.-stories .ft2-header_background {
        background-image: url($image-path + 'ft-stories-header-bg.jpg');
    }

    &.-cooperative .ft2-header_background {
        background-image: url($image-path + 'ft-coop-header-map.png');
    }
}
