.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.hide {
    display: none;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.transparent {
    opacity: 0;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}


[hidden] {
    display: none !important;
}

.pointer {
    cursor: pointer;
}

.no-scroll {
    overflow: hidden !important;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

.static-image {
    max-width: none !important;
}
