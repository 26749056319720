$toggle: (
    height: 30px,
    width: 70px,
    on-color: #93C24F,
    off-color: #60696f
);


mat-slide-toggle.mat-slide-toggle {
    * {
        font-family: $text-family;
    }

    .mat-slide-toggle-thumb, .mat-slide-toggle-thumb-container {
        height: map-get($toggle, 'height') - 4px;
        width: map-get($toggle, 'height') - 4px;
    }

    .mat-slide-toggle-input {
        display: none;
    }

    .mat-slide-toggle-thumb-container {
        top: 2px;
        left: 2px;
    }

    .mat-slide-toggle-bar {
        height: map-get($toggle, 'height');
        width: map-get($toggle, 'width');
        background: map-get($toggle, 'off-color');
        border-radius: map-get($toggle, 'height') / 2;
        transition: all .3s linear;
        position: relative;
        overflow: hidden;

        &:before {
            content: 'Off';
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translate(0, -50%);
            color: $white;
            font-size: $medium-font-size;
            font-family: $text-family;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        }

        &:after {
            content: '';
            @include overlay;
            background: $gray-500;
            z-index: -1;
            border-radius: 10px;
        }
    }

    .mat-slide-toggle-content {
        color: inherit;
        overflow: visible;
    }


    .mat-slide-toggle-ripple {
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
        height: 30px;
        width: 30px;
        z-index: 1;
        pointer-events: none;
        opacity: 0.6;

        .mat-ripple-element {
            top: calc(50% - 15px) !important;
            left: calc(50% - 15px) !important;
            height: 30px !important;
            width: 30px !important;
            background: $dark-gradient !important;
        }
    }

    &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
        transform: translate3d(map-get($toggle, 'width') - map-get($toggle, 'height'), 0, 0);
    }

    .mat-slide-toggle-thumb {
        background: $white !important;
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .2), 0 2px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
        z-index: 1000;
    }


    &.mat-checked .mat-slide-toggle-bar {
        background: map-get($toggle, 'on-color');

        &:before {
            content: 'On';
            right: auto;
            left: 12px;
        }
    }

    &.mat-slide-toggle-label-before .mat-slide-toggle-bar, [dir=rtl] .mat-slide-toggle-bar {
        margin-left: 15px;
    }
}

