.ft2-daterange-placeholder {
    @include solid-family(16px);
    position: relative;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .15);
    padding: 15px 20px;
    text-transform: uppercase;
    margin: 30px auto;
    transition: .3s cubic-bezier(0, 0, .1, 1);
    border: 1px solid $gray-200;
    background: $white;
    width: 250px;
    text-align: center;
}

.ft2-content-img {
    width: 100%;
    max-width: 450px;
}
