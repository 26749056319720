.ft2-badge {
    background: $primary-color;
    padding: 0.3em;
    line-height: 1 !important;
    position: relative;
    text-transform: uppercase;
    border-radius: 2px;
    font-family: $solid-family;
    color: $white;
    display: inline-block;

    &:before {
        top: 100%;
        left: 10px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-top-color: $primary-color;
        border-width: 4px;
        margin-left: -4px;
        transform: rotate(-90deg) translate(4px, 0px);
    }

    &.-arrow_right {
        margin-right: 10px;

        &:before {
            left: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: transparent;
            border-left-color: $primary-color;
            border-width: 4px;
            margin-top: -4px;
            transform: none;
        }
    }
}

.ft2-status-badge {
    display: inline-flex;
    max-width: 120px;
    margin: 0 auto;
    padding: 0px 15px;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
    color: $white;
    background: $gray-700;
    border-radius: 100px;
}



small.ft2-collaborative-badge {
    font-size: 11px;
}

.ft2-collaborative-badge {
    @include solid-family;
    border-radius: 1px;
    background: $white;
    color: $base-color;
    padding: 2px 1em;
    margin: 20px 0;
    display: inline-block;

    &.-dark {
        background: $base-color;
        color: $white;
    }
}
