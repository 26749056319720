/*--------------------------------------/
 Settings
--------------------------------------*/
$input: (
    height: 44px,
    padding: 15px,
    border-radius: 4px,
    font-size: $base-font-size,
    font-weight: 300,
    border: 1px solid $gray-200,
    line-height: 1.4,
    width: 100%
);

.ft2-input {
    color: $base-color;
    font-weight: map-get($input, 'font-weight');
    border: map-get($input, 'border');
    font-size: map-get($input, 'font-size');
    max-width: 100%;
    padding: 0 map-get($input, 'padding');
    height: map-get($input, 'height');
    font-family: $text-family;
    border-radius: map-get($input, 'border-radius');
    width: map-get($input, 'width');
    line-height: map-get($input, 'line-height');
    transition: border-color $transition;
    background: $white;

    & + .ft2-input, & + .ft2-input-wrapper {
        margin-top: 2px;
    }

    @include placeholder {
        color: $gray-600;
    }

    &:focus {
        border-color: $gray-400;
    }

    &:disabled, &.-disabled {
        background-color: rgba($gray-400, .3);
        cursor: default;
        color: $gray-500 !important;
        -webkit-text-fill-color: inherit;
    }

    &.-dark {
        background: $gray-600;
        border-color: $gray-600;
        color: $light-color;

        &:disabled, &.-disabled {
            background-color: rgba($gray-600, .3);
            border-color: rgba($gray-600, .3);
        }

        @include placeholder{
            color: $gray-300;
        }

    }

    &.-pure {
        border: none;
        background: none;
    }

    &.-large {
        height: map-get($input, 'height') + 10px;
    }

    &.-medium {
        height: map-get($input, 'height');
    }

    &.-small {
        height: map-get($input, 'height') - 10px;
        font-size: map-get($input, 'font-size') - 2px;
    }

    &.-width_auto {
        width: auto;
    }

    &.-wide {
        width: 100%;
    }

    &.-round {
        border-radius: 10em;
        padding-left: map-get($input, 'padding');
        padding-right: map-get($input, 'padding');
    }

    &.-radius {
        border-radius: map-get($input, 'border-radius') * 2;
    }

    &.-square {
        border-radius: 0;
    }

    &.-center {
        text-align: center;
    }
}

/*--------------------------------------/
 Textarea
--------------------------------------*/

.ft2-textarea {
    @extend .ft2-input;
    padding-top: map-get($input, 'padding');
    padding-bottom: map-get($input, 'padding');
    min-width: 100%;
    min-height: 10em;
    height: auto;

    &.-static {
        resize: none;
    }
}


/*--------------------------------------/
 Input groups
--------------------------------------*/

.ft2-input-group {
    display: flex;

    .ft2-input {
        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
        }

        &:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
        }
    }

    &_prepend {
        > * {
            height: 100% !important;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: none;
            min-width: 60px;
        }
    }

    &_append {
        > * {
            height: 100% !important;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            box-shadow: none;
            min-width: 60px !important;
        }
    }
}

/*--------------------------------------/
 Input icons
--------------------------------------*/

.ft2-input-wrapper {
    position: relative;

    & + .ft2-input, & + .ft2-input-wrapper {
        margin-top: 2px;
    }

    .ft2-input-icon {
        position: absolute;
        top: 50%;
        left: 0.7em;
        transform: translate(0, -50%);
        display: flex;
        align-items: center;

        & + .ft2-input {
            padding-left: 2em;
        }
    }

    .ft2-input + .ft2-input-icon {
        left: auto;
        right: 0.7em;
    }
}
