/*--------------------------------------/
 Settings
--------------------------------------*/
$button: (
    height: 32px,
    padding: 15px,
    border-radius: 4px,
    font-weight: 500,
    text-transform: none,
    box-shadow: 0 6px 12px -6px rgba(0, 0, 0, 0.6),
    border: none
);

/*--------------------------------------/
 Constructor
--------------------------------------*/
@mixin create-button($selector, $gradient, $color) {
    @if $color == null {
        $color: #fff;
    }

    #{"" + $selector} {
        @extend .ft2-btn;
        color: color-yiq($color);
        background-color: transparent;
        background-image: $gradient;


        &:disabled, &.-disabled {
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
        }

        &.-outline {
            color: $color;
            border: 1px solid $color;

            &:hover {
                color: darken($color, 15);
                border-color: darken($color, 15);
            }
        }
    }
}

/*--------------------------------------/
 Base class
--------------------------------------*/

.ft2-btn {
    height: map-get($button, 'height');
    line-height: map-get($button, 'height') - 2px;
    padding: 0 map-get($button, 'padding');
    border-radius: map-get($button, 'border-radius');
    font-weight: map-get($button, 'font-weight');
    text-transform: map-get($button, 'text-transform');
    box-shadow: map-get($button, 'box-shadow');
    background-repeat: no-repeat;
    margin: 0;
    font-family: $text-family;
    font-size: $button-font-size;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    border: none;
    text-align: center;
    background-position: center bottom;
    background-size: 100% 100%;
    transition: color $transition, opacity $transition, background-size $transition;

    &:hover {
        text-decoration: none;
        background-size: 100% 250%;
    }

    &.-outline {
        background: transparent;
        overflow: hidden;
        box-shadow: none;
    }

    &.-rounded {
        border-radius: map-get($button, 'height') / 2;
        padding-left: 20px;
        padding-right: 20px;
    }

    &.-large {
        height: map-get($button, 'height') + 10px;
        line-height: map-get($button, 'height') + 8px;
        padding: 0 map-get($button, 'padding') + 5px;
        font-size: $button-font-size + 2px;
        font-weight: 500;
        min-width: 130px;

        &.-outline {
            font-size: $button-font-size;
        }
    }

    &.-huge {
        height: map-get($button, 'height') + 20px;
        line-height: map-get($button, 'height') + 18px;
        padding: 0 map-get($button, 'padding') + 10px;
        font-size: $button-font-size + 4px;
        min-width: 200px;
        font-weight: 500;
    }

    &.-small {
        height: 25px;
        line-height: 23px;
        padding: 0 15px;
        font-size: 13px;
    }

    &.-wide {
        width: 100%;
    }
}

/*--------------------------------------/
 Customs
--------------------------------------*/

.ft2-btn-pure {
    font-family: $text-family;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    box-shadow: none;
    outline: none;
}

.ft2-btn-icon {
    @extend .ft2-btn-pure;
    line-height: 0;
    padding: 0.4em;
    border-radius: 50%;
    overflow: hidden;
    background: transparent;
    transition: background $transition, opacity $transition;
    opacity: 0.7;
    color: $base-color;

    &:hover {
        background: rgba($black, 0.07);
        opacity: 1;
    }

    &.-dark {
        color: $white;

        &:hover {
            background: rgba($white, 0.07);
        }
    }
}

.ft2-btn-text {
    background: none;
    border: none;
    box-shadow: none;
    font-family: $text-family;
    color: $base-color;
    font-size: $button-font-size;
    opacity: 0.7;
    transition: opacity .3s linear;
    padding: 0;
    font-weight: map-get($button, 'font-weight');


    &:hover {
        opacity: 1;
    }
}

.ft2-btn-shade {
    @extend .ft2-btn;
    color: $base-color;
    background: transparent;
    opacity: 0.7;
    box-shadow: none;
    border: none;

    &:hover {
        opacity: 1;
        background: rgba($black, 0.07);
    }

    &.-dark {
        color: $white;

        &:hover {
            background: rgba($white, 0.07);
        }
    }
}


/*--------------------------------------/
 Theme buttons
--------------------------------------*/
@each $color, $gradient in $theme-gradients {
    @include create-button(".ft2-btn-#{$color}", $gradient, theme-color($color));
}

//@include create-button(".ft2-btn-default", linear-gradient(to bottom, $gray-600, $gray-500), $gray-500);


.ft2-btn-dark.-outline {
    border-color: $gray-400;
}

.ft2-btn-light.-outline {
    border-color: rgba($white, 0.5);

    &:hover {
        color: $white;
        border-color: $white;
    }
}

.ft2-btn-default {
    @extend .ft2-btn;
    box-shadow: none;
    background: $white;
    border: 1px solid $gray-400;
    transition: all $transition;

    &:hover {
        border-color: $gray-700;
    }
}

/*--------------------------------------/
 Social buttons
--------------------------------------*/

@each $color, $gradient in $social-gradients {
    @include create-button(".ft2-btn-#{$color}", $gradient, transparent);
}

/*--------------------------------------/
 Button groups
--------------------------------------*/

.ft2-btn-group {
    display: flex;

    &.-vertical {
        display: inline-flex;
        flex-direction: column;

        .ft2-btn {
            border-top: none;
            border-left: 1px solid $gray-400;

            &:first-child {
                border-top: 1px solid $gray-400;
                border-radius: map-get($button, 'border-radius') map-get($button, 'border-radius') 0 0;
            }

            &:last-child {
                border-radius: 0 0 map-get($button, 'border-radius') map-get($button, 'border-radius');
            }
        }
    }

    .ft2-btn {
        border-radius: 0;
        box-shadow: none;
        background: $gray-100;
        border: 1px solid $gray-400;
        color: darken($gray-500, 15);
        border-left: none;

        &.-active {
            background: $white;
            color: $gray-700;
        }

        &:hover {
            color: $gray-700;
        }

        &:first-child {
            border-radius: map-get($button, 'border-radius') 0 0 map-get($button, 'border-radius');
            border-left: 1px solid $gray-400;
        }

        &:last-child {
            border-radius: 0 map-get($button, 'border-radius') map-get($button, 'border-radius') 0;
        }
    }
}

/*--------------------------------------/
 Close button
--------------------------------------*/
.ft2-btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 36px;
    height: 36px;
    color: inherit;
    background: none;
    border: none;
    outline: none;
    z-index: 100;
    transition: background $transition, opacity $transition;
    opacity: 0.5;
    cursor: pointer;
    border-radius: 50%;

    &.-bg_light {
        background: rgba($white, 0.7);
    }

    &.-bg_dark {
        background: rgba($darker-color, 0.7);
    }

    &.-fixed {
        position: fixed;
        right: 15px;
    }

    &.-static {
        position: static;
    }

    &:hover {
        opacity: 1;
    }

    &:before, &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 75%;
        height: 2px;
        border-radius: 1px;
        box-shadow: inset 0 0 0 1px;
    }

    &:before {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
}

.ft2-btn-info {
    @extend .ft2-btn-pure;
    font-family: $icon-family;
    color: inherit;
    font-size: 1rem;
    opacity: 0.5;
    transition: opacity $transition;
    margin: 0 2px 2px;
    vertical-align: middle;

    &:hover, &:active, &:focus {
        opacity: 1;
    }

    &:after {
        transform: rotate(180deg);
        display: inline-block;
        content: "error_outline";
    }
}
